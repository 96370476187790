import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { Add, Remove } from "@mui/icons-material";
import "./styles.css";

const App = () => {
  const [open, setOpen] = useState(false);
  const [openData, setOpenData] = useState(false);
  const [openGames, setOpenGames] = useState(false);
  const [openPortfolio, setOpenPortfolio] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 1500); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDataClick = () => {
    setOpenData(!openData);
  };

  const handleGamesClick = () => {
    setOpenGames(!openGames);
  };

  const handlePortfolioClick = () => {
    setOpenPortfolio(!openPortfolio);
  };

  const handleLinkClick = (url) => {
    window.location.href = url;
  };

  return (
    <div style={{ textAlign: "center", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "400px" }}>
      <Typography variant="h1" onClick={handleClick} style={{ cursor: "pointer" }}>
        talele
      </Typography>
      <Collapse in={open} timeout={500} unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton onClick={handleDataClick}>
            <ListItemText primary="data" />
            {openData ? <Remove /> : <Add />}
          </ListItemButton>
          <Collapse in={openData} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ paddingLeft: "2rem" }}>
              {DATA.map((item, index) => (
                <ListItemButton key={index} onClick={() => handleLinkClick(item.url)}>
                  <ListItemText primary={item.name} secondary={item.url} className="child-text" />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <ListItemButton onClick={handleGamesClick}>
            <ListItemText primary="games" />
            {openGames ? <Remove /> : <Add />}
          </ListItemButton>
          <Collapse in={openGames} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ paddingLeft: "2rem" }}>
              {GAMES.map((item, index) => (
                <ListItemButton key={index} onClick={() => handleLinkClick(item.url)}>
                  <ListItemText primary={item.name} secondary={item.url} className="child-text" />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <ListItemButton onClick={handlePortfolioClick}>
            <ListItemText primary="portfolio" />
            {openPortfolio ? <Remove /> : <Add />}
          </ListItemButton>
          <Collapse in={openPortfolio} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ paddingLeft: "2rem" }}>
              {PORTFOLIO.map((item, index) => (
                <ListItemButton key={index} onClick={() => handleLinkClick(item.url)}>
                  <ListItemText primary={item.name} secondary={item.url} className="child-text" />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </List>
      </Collapse>
    </div>
  );
};

const DATA = [
  { name: "WikiClouds", url: "https://wiki.talele.co.in" },
  { name: "Colour Map", url: "https://map.talele.co.in" },
  { name: "TV Analyzer", url: "https://tva.talele.co.in" }
];

const GAMES = [
  { name: "wordle (takes time to start server)", url: "https://wordle.talele.co.in" }
];

const PORTFOLIO = [
  { name: "thelighterclub", url: "https://tlc.talele.co.in" },
  { name: "alif", url: "https://alif.talele.co.in" }
];

export default App;
